<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template>
      <div class="row my-1 mx-2">
        <v-btn
          depressed
          color="primary"
          dark
          @click="OpenDialog('lead', 'none')"
          >+ Add Lead</v-btn
        >

        <v-btn class="mx-2" depressed color="info" dark @click="ImportLead()"
          ><v-icon class="mr-1">mdi-cloud-upload</v-icon> Import Lead</v-btn
        >

        <v-spacer></v-spacer>
        <template v-if="isSuper">
          <v-btn class="mx-2" depressed color="info" dark @click="googleSheet()"
            ><v-icon class="mr-1">mdi-cog</v-icon> Google Sheet</v-btn
          >
        </template>
      </div>
    </template>

    <v-card class="rounded-xl overflow-hidden mx-auto">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <v-toolbar-title> Leads Center </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="d-flex mx-4 mt-4">
          <template v-if="isAdmin || isSuper || isSupervisor">
            <div>
              <v-switch
                class="mt-2"
                inset
                color="error"
                @change="getLeads()"
                v-model="body.noCoordinator"
                :loading="loading"
                :label="`No ${getCoordinatorN}`"
              ></v-switch>
            </div>
          </template>

          <div>
            <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              hide-details
              clearable
              outlined
              @change="getLeads()"
              dense
              class="mx-2"
              :items="references"
              item-text="name"
              item-value="name"
              v-model="body.howKnow"
              label="Filter by Source"
            >
              <template v-slot:prepend-item>
                <v-text-field
                  class="px-2"
                  prepend-inner-icon="mdi-magnify-plus-outline"
                  placeholder="Custom source"
                  clearable
                  @click:clearable="getLeads()"
                  @change="getLeads()"
                  v-model="body.howKnow"
                ></v-text-field>
              </template>
              <template v-slot:selection>
                <span>{{ body.howKnow }}</span>
              </template>
            </v-autocomplete>
          </div>

          <template v-if="isAdmin || isSuper || isSupervisor">
            <div>
              <v-autocomplete
                class="mr-1"
                :label="`Filter by Employees`"
                :items="workers"
                v-model="body.coordinatorUuid"
                item-text="fullname"
                item-value="uuid"
                style="max-width: 500px"
                prepend-inner-icon="mdi-account-search"
                clearable
                @change="getLeads()"
                hide-details
                outlined
                dense
              ></v-autocomplete>
            </div>
          </template>
          <div>
            <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              hide-details
              clearable
              outlined
              @change="getLeads()"
              dense
              class="mx-2"
              :items="status"
              item-text="name"
              item-value="id"
              v-model="body.status"
              label="Filter by Status"
            >
            </v-autocomplete>
          </div>
        </div>
        <v-spacer />
        <template
          v-if="(isAdmin || isSuper || isSupervisor) && selected.length != 0"
        >
          <asign-to
            :ocupations="ocupations"
            :color="'yellow'"
            @assigned="getLeads"
            :leadUuids="selected"
          />
        </template>
        <template
          v-if="(isAdmin || isSuper || isSupervisor) && selected.length != 0"
        >
          <asign-delete
            :ocupations="ocupations"
            :color="'error'"
            @deleted="getLeads"
            :leadUuids="selected"
          />
        </template>
      </v-toolbar>

      <v-container>
        <v-row class="my-1 mx-2">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.trim="body.query"
              class="mr-2"
              label="Search or filter"
              hide-details
              clearable
              @change="getLeads()"
              outlined
              @click:clear="refresh()"
              dense
            />
          </v-col>
          <v-col cols="12" sm="6">
            <template>
              <div class="row">
                <div class="col-sm-8">
                  <v-row no-gutters>
                    <v-col>
                      <ma-date-picker
                        :datepickerProps="{
                          'no-title': true,
                        }"
                        v-model="dateFrom"
                        label="From"
                        past
                      />
                    </v-col>
                    <v-col class="ml-2">
                      <ma-date-picker
                        :datepickerProps="{
                          'no-title': true,
                        }"
                        v-model="dateTo"
                        label="To"
                        past
                      />
                    </v-col>
                  </v-row>
                </div>
                <div class="col-sm-3">
                  <v-btn
                    class="mx-2"
                    fab
                    small
                    dark
                    color="indigo"
                    @click="refresh('dates')"
                  >
                    <v-icon dark> mdi-refresh </v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
        <template>
          <v-data-table
            v-model="selected"
            :loading="loading || loadingAcciones"
            :headers="headers"
            :options.sync="options"
            :items="leads"
            :footer-props="footerProps"
            :show-select="isAdmin || isSuper || isSupervisor"
            :server-items-length="totalleads"
            item-key="uuid"
            class="elevation-1"
          >
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name + " " + item.last_name }}
            </template>
            <template v-slot:[`item.coordinator`]="{ item }">
              {{ getCoordinator(item) }}
            </template>
            <template v-slot:[`item.rd`]="{ item }">
              {{ prettyDate(getRDate(item)) }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-menu offset-x>
                <template v-slot:activator="{ on }">
                  <span
                    v-on="on"
                    v-bind:style="{ color: colorShow(item) }"
                    class="font-weight-bold cursor-pointer"
                    >{{ statusShow(item) }}</span
                  >
                </template>
                <v-list>
                  <v-list-item
                    class="cursor-pointer"
                    v-for="(st, index) in status"
                    :key="index"
                    link
                    :disabled="statusShowReal(item) == st.name"
                    @click="changeStatus(item, st)"
                  >
                    <v-list-item-title
                      :class="
                        statusShowReal(item) == st.name ? 'red--text' : ''
                      "
                      >{{ st.name }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`item.createAt`]="{ item }">
              {{ formatDate(item.createAt) }}
            </template>
            <template v-slot:[`item.howKnow`]="{ item }">
              {{ item.howKnow ? item.howKnow : "-" }}
            </template>
            <template v-slot:[`item.phone`]="{ item }">
              {{
                item.phone == null || item.phone == undefined ? "-" : item.phone
              }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <router-link
                      class="leaddetails"
                      :to="'/leads_details/' + item.uuid"
                      ><v-icon v-on="on" color="success"
                        >mdi-eye</v-icon
                      ></router-link
                    >
                  </template>

                  <span>Lead Details</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      icon
                      color="primary"
                      @click="goEdit(item)"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                  </template>

                  <span>Edit Lead</span>
                </v-tooltip>
                <template v-if="isSuper || isAdmin">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        small
                        icon
                        @click="toDelete(item)"
                        color="error"
                        ><v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>

                    <span>Delete Lead</span>
                  </v-tooltip>
                </template>
              </div>
            </template>
          </v-data-table>
        </template>
      </v-container>

      <!--  Delete Lead -->
      <v-dialog
        v-model="deleteL.deleteDialog"
        persistent
        :overlay="false"
        max-width="300px"
        transition="dialog-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            Delete Lead: {{ deleteL.deleteItem.name }}
          </v-toolbar>
          <v-card-text class="mt-3">
            <div class="pa-2">Are you sure ?</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDelete" color="error">Cancel</v-btn>
            <v-btn
              text
              :loading="loadingAcciones"
              @click="confirmDelte"
              color="primary"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
/* import { Container, Draggable } from "vue-smooth-dnd"; */
/* import LeadDashboardGroup from "./LeadDasboardGroup.vue"; */

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import SendEmail from "@/components/emails/SendEmail.vue";
import TableLeads from "@/views/Configurations/components/TableLeads.vue";

import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import TableCalls from "@/views/Configurations/components/TableCalls.vue";
import BtnMakeCall from "@/views/CallCenter/buttons/BtnMakeCall.vue";

import AsignTo from "@/views/Configurations/components/AsignTo.vue";
import AsignDelete from "@/views/Configurations/components/AsignDelete.vue";
import DrawerSms from "@/views/SMS/components/DrawerSms.vue";
import { Lead, LeadStatus } from "@/models/Lead";

import { desireSurgery } from "@/utils/consts";
import { getAPI } from "@/api/axios-base";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

import moment from "moment";
import AllImages from "@/components/Images/AllImages.vue";

export default Vue.extend({
  components: {
    /*  LeadDashboardGroup, */
    /* Draggable, */
    /*  Container, */
    SendEmail,
    MaDatePicker,
    DrawerSms,
    TableLeads,
    TableCalls,
    BtnMakeCall,
    AsignTo,
    AllImages,
    AsignDelete,
  },
  data: () => ({
    ocupations: [],

    loadingAcciones: false,
    deleteL: {
      deleteItem: {},
      deleteDialog: false,
    },
    desireSurgery,
    showcalendar: false,
    changed: false,
    formValid: false,

    evaluation: false,
    tabSelect: null,
    title: "",
    types: [],
    footerProps: { "items-per-page-options": [15, 25, 50, 100, 500, 1000] },
    details: {},

    options: {},
    dialogNote: false,
    dialogLead: false,
    selected: [],
    detailsnote: "",
    loadingProcedures: false,

    dateTo: "",
    dateFrom: "",
    uuidLead: "",
    body: {
      query: null,
      dates: null,
      status: null,
      noCoordinator: false,
      coordinatorUuid: null,
      howKnow: null,
      interval: {
        offset: 10,
        limit: 0,
      },
    },
    upperDropPlaceholderOptions: {
      className: "cards-drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    expanded: [],
    status: [],
  }),
  computed: {
    ...mapGetters([
      "getCoordinatorN",
      "getpatientN",
      "isSupervisor",
      "isAdmin",
      "isSuper",
      "getleadHeaders",
      "getMethodCalls",
    ]),
    ...mapState("crmConfigurationsModule", ["leads", "loading", "totalleads"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
    ...mapState("crmEmployeeModule", ["doctors", "workers"]),

    ...mapState("crmCallCenterModule", ["callsLead"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    /*  dateRangeText() {
      let temp = (this).body.dates;
      if ((this).body.dates.length > 1) {
        const val1 = (this).body.dates[0].split("-").splice(2, 1);
        const val2 = (this).body.dates[1].split("-").splice(2, 1);
        if (Number(val1) > Number(val2)) {
          temp = [];
          temp.push((this).body.dates[1]);
          temp.push((this).body.dates[0]);
        }
      }
      return temp.join(" ~ ");
    }, */

    desireSurgerySelect() {
      if (
        this.expanded.length == 0 ||
        this.expanded[0].medicalHistory == null
      ) {
        return null;
      }
      if (this.expanded[0].medicalHistory.desiredSurgeryDate) {
        const t = this.expanded[0].medicalHistory.desiredSurgeryDate;
        const desire = this.desireSurgery.filter((d) => d.value == t);
        return desire[0];
      }
      return null;
    },

    headers() {
      if (this.getleadHeaders == "email") {
        return [
          {
            text: "Creation Date",
            align: "start",
            sortable: false,
            value: "createAt",
          },
          {
            text: "Name",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "Email", value: "email", sortable: false },
          { text: "Source", value: "howKnow", sortable: false },
          //{ text: "Phone", value: "phone" },
          { text: this.getCoordinatorN, value: "coordinator", sortable: false },
          {
            text: "R-Date",
            align: "start",
            width: 110,
            value: "rd",
            sortable: false,
          },
          { text: "Lead Status", value: "status", sortable: false },
          {
            text: "Calls",
            value: "callCount",
            align: "center",
            sortable: false,
          },
          { text: "Sms", value: "smsCount", sortable: false },
          { text: "", value: "actions", sortable: false },
        ];
      }
      if (this.getleadHeaders == "phone") {
        return [
          {
            text: "Creation Date",
            align: "start",
            sortable: false,
            value: "createAt",
          },
          {
            text: "Name",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "Phone", value: "phone", sortable: false },
          { text: "Source", value: "howKnow", sortable: false },
          //{ text: "Phone", value: "phone" },
          { text: this.getCoordinatorN, value: "coordinator", sortable: false },
          {
            text: "R-Date",
            align: "start",
            width: 110,
            value: "rd",
            sortable: false,
          },
          { text: "Lead Status", value: "status", sortable: false },
          {
            text: "Calls",
            value: "callCount",
            align: "center",
            sortable: false,
          },
          { text: "Sms", value: "smsCount", sortable: false },
          { text: "", value: "actions", sortable: false },
        ];
      }
      if (this.getleadHeaders == "both") {
        return [
          {
            text: "Creation Date",
            align: "start",
            sortable: false,
            value: "createAt",
          },
          {
            text: "Name",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "Email", value: "email", sortable: false },
          { text: "Phone", value: "phone", sortable: false },
          { text: "Source", value: "howKnow", sortable: false },
          //{ text: "Phone", value: "phone" },
          { text: this.getCoordinatorN, value: "coordinator", sortable: false },
          {
            text: "R-Date",
            align: "start",
            width: 110,
            value: "rd",
            sortable: false,
          },
          { text: "Lead Status", value: "status", sortable: false },
          {
            text: "Calls",
            value: "callCount",
            align: "center",
            sortable: false,
          },
          { text: "Sms", value: "smsCount", sortable: false },
          { text: "", value: "actions", sortable: false },
        ];
      }
    },
    bmi() {
      let inches = 0;
      let feets = 0;
      if (this.expanded.length == 0) {
        return 0;
      }
      if (this.expanded[0].weight == 0 && this.expanded[0].height == 0)
        return 0;
      const he = this.expanded[0].height.toString();
      if (he.includes(".")) {
        inches = Number(he.split(".")[1]);
        feets = he.split(".")[0] ? Number(he.split(".")[0]) : 0;
      } else if (he.includes(",")) {
        inches = Number(he.split(",")[1]);
        feets = he.split(".")[0] ? Number(he.split(",")[0]) : 0;
      } else {
        feets = he;
      }

      const inchtometer = inches * 0.0254;
      const feettometer = feets * 0.3048;
      const bmi =
        (Number(this.expanded[0].weight) * 0.453592) /
        Math.pow(feettometer + inchtometer, 2);

      return Number(bmi).toFixed(2);
    },

    typebmi() {
      const bmitemp = Number(this.bmi);

      if (bmitemp == 0) {
        return "No BMI";
      }
      if (bmitemp <= 18.5) {
        return "Underweight";
      }
      if (bmitemp > 18.5 && bmitemp <= 24.9) {
        return "Normal weight";
      }
      if (bmitemp >= 25 && bmitemp <= 29.9) {
        return "Overweight";
      }

      return "Obesity";
    },
    colorWeight() {
      if (this.typebmi == "No BMI") {
        return "grey";
      }
      if (this.typebmi == "Underweight") {
        return "amber";
      }
      if (this.typebmi == "Normal weight") {
        return "green";
      }
      if (this.typebmi == "Overweight") {
        return "orange";
      }
      if (this.typebmi == "Obesity") {
        return "red darken-2";
      }
    },
  },
  async mounted() {
    await this.loadLeadStatus();
    this.listOcupations();
    this.actListReferences();
    this.actGetPatientDoctors();

    this.actGetEmployees();

    localStorage.removeItem("from");
  },
  watch: {
    expanded(val) {
      if (val != []) {
        this.expandedLead();
      }
    },
    options(val) {
      if (val != {}) {
        this.getLeads();
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.getLeads();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.getLeads();
      }
    },
  },
  destroyed() {
    localStorage.removeItem("leadcenter");
  },
  methods: {
    ...mapActions("crmConfigurationsModule", [
      "actGetLeads",
      "actConverToPatient",
      "actAddNote",
      "actAddLead",
    ]),

    ...mapActions("crmEmployeeModule", [
      "actGetEmployees",
      "actGetPatientDoctors",
    ]),
    ...mapActions("crmCallCenterModule", ["actFilterCalls"]),
    ...mapMutations("crmConfigurationsModule", ["mutleadSelect"]),
    ...mapActions("crmSettingsModule", ["actListReferences"]),
    gotoLead(item) {
      this.$router.push(`/leads_details/${item.uuid}`);
    },

    changeStatus(item, status) {
      this.loadingAcciones = true;
      let body = {
        leadUuid: item.uuid,
        statusNom: status.id,
      };
      getAPI
        .put("/social/updateCounts", body)
        .then(() => {
          notifyInfo("Lead Status has been changed");
          this.loadingAcciones = false;
          this.getLeads();
        })
        .catch((error) => {
          this.loadingAcciones = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    loadLeadStatus() {
      getAPI(`/lead-status-nom/list`)
        .then((res) => {
          this.status = res.data.filter((f) => f.status != "NO_ACTIVE");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    async listOcupations() {
      this.ocupations = (await getAPI(`/sprocedures/listNom/Ocupation`)).data;
    },
    statusShow(item) {
      const est = item.statusNom ? item.statusNom.name : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }
      switch (est) {
        case LeadStatus.NotInterested:
          return "Not Interested";
        case LeadStatus.WrongNumber:
          return "Wrong Number";
        default:
          return est;
      }
    },
    statusShowReal(item) {
      const est = item.statusNom ? item.statusNom.name : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }
      return est;
    },
    colorShow(item) {
      return item.statusNom ? item.statusNom.color : "#000";
    },

    convertToPatient(lead) {
      this.mutleadSelect(lead);
      this.$router.push("/patients/create");
    },
    goEdit(item) {
      localStorage.setItem("from", "center");
      this.$router.push("/leads_center/edit/" + item.uuid);
    },
    async expandedLead() {
      if (this.expanded.length != 0) {
        this.uuidLead = this.expanded[0].uuid;
        this.details = this.expanded[0];
        const phone = this.expanded[0].phone;
        if (this.getMethodCalls == "twilio") {
          await this.actFilterCalls({
            whateverNumber: phone,
            limit: 500,
            offset: 0,
          });
        }
      } else {
        this.uuidLead = "";
        this.details = {};
      }
    },

    toIsoString(values) {
      let temp = [];
      if (values.length == 1 || values[0] == values[1]) {
        const date1 = new Date(values[0]);
        const date2 = new Date(values[0]);
        date2.setUTCHours(23, 59);
        temp.push(date1.toISOString());
        temp.push(date2.toISOString());
      } else {
        values.forEach((val) => {
          const date = new Date(val);
          temp.push(date.toISOString());
        });
      }

      return temp;
    },

    toDelete(item) {
      this.deleteL.deleteDialog = true;
      this.deleteL.deleteItem = item;
    },
    cancelDelete() {
      this.deleteL = { deleteItem: {}, deleteDialog: false };
    },
    confirmDelte() {
      this.loadingAcciones = true;
      getAPI
        .delete("/social/delete/" + this.deleteL.deleteItem.uuid)
        .then(() => {
          notifyInfo("Lead has been deleted");
          this.loadingAcciones = false;
          this.cancelDelete();
          this.getLeads();
        })
        .catch((error) => {
          this.loadingAcciones = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getLeads() {
      this.selected = [];
      let reqbody = {};
      if (
        localStorage.getItem("leadcenter") &&
        localStorage.getItem("filtersleads")
      ) {
        const localdata = JSON.parse(localStorage.getItem("filtersleads"));
        this.body = localdata.body;
        this.dateTo = localdata.dateto;
        this.dateFrom = localdata.datefrom;
        this.options.page = localdata.page;
        this.options.itemsPerPage = localdata.itemsPerPage;
      }
      reqbody = {
        query: this.body.query,
        status: this.body.status,
        noCoordinator: this.body.noCoordinator,
        coordinatorUuid: this.body.coordinatorUuid,
        interval: this.body.interval,
        howKnow: this.body.howKnow,
      };
      if (
        (this.dateTo != "" &&
          this.dateTo != null &&
          this.dateTo != undefined &&
          this.dateFrom != "" &&
          this.dateFrom != null &&
          this.dateFrom != undefined) ||
        this.body.dates != null
      ) {
        if (this.body.dates != null) {
          reqbody = { ...reqbody, dateInterval: this.body.dates };
        } else {
          reqbody = {
            ...reqbody,
            dateInterval: {
              date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
              date2: moment(this.dateTo).endOf("day").utc().toISOString(),
            },
          };
        }
      }
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != undefined ? itemsPerPage : 15;
      const p = page != undefined ? page : 1;
      const reqrange = {
        limit: itmper,
        offset: (p - 1) * itmper,
      };
      reqbody.interval = reqrange;
      this.body = {
        query: reqbody.query,
        status: reqbody.status,
        noCoordinator: reqbody.noCoordinator,
        interval: reqbody.interval,
        dates: reqbody.dateInterval ? reqbody.dateInterval : null,
        coordinatorUuid: reqbody.coordinatorUuid,
        howKnow: reqbody.howKnow,
      };
      reqbody = this.cleanNull(reqbody);

      localStorage.setItem(
        "filtersleads",
        JSON.stringify({
          body: this.body,
          dateto: this.dateTo,
          datefrom: this.dateFrom,
          page,
          itemsPerPage,
        })
      );
      localStorage.removeItem("leadcenter");
      this.actGetLeads(reqbody);
    },

    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },
    getHeight(height) {
      if (height == undefined || height == null || height == "") {
        return 0;
      }
      const ht = height.toString();
      if (ht.includes(".")) {
        return ht.split(".")[0] + " ft, " + ht.split(".")[1] + " in";
      } else if (ht.includes(",")) {
        return ht.split(",")[0] + " ft, " + ht.split(",")[1] + " in";
      } else {
        return ht + " ft";
      }
    },

    getDoctorName(item) {
      if (item.medicalHistory.doctorChoice != null) {
        return item.medicalHistory.doctorChoice.fullname;
      }
      return "None";
    },

    /*  formatTime(date) {
      if (!date) return null;
      const [fecha, hora] = date.split("T");
      let [hours, minutes, seconds] = hora.split(":");
      seconds = seconds.substr(-20, 2);
      return `${hours}:${minutes}:${seconds}`;
    }, */

    showDetails(patient) {
      this.$router.push(`/patients/details/${patient.uuid}`);
    },

    OpenDialog(type, uuid) {
      switch (type) {
        case "note":
          this.dialogNote = true;
          break;
        default:
          this.$router.push(`/leads_center/new_lead`);

          break;
      }
      this.uuidLead = uuid ? uuid : "none";
    },
    ImportLead() {
      this.$router.push(`/leads_center/import_lead`);
    },
    googleSheet() {
      this.$router.push(`/leads_center/google_sheet_list`);
    },

    updateFiles() {
      setTimeout(() => {
        this.$refs.images.getImages();
      }, 500);
    },

    async addNote() {
      await this.actAddNote({
        details: this.detailsnote,
        leadUuid: this.uuidLead,
        type: this.evaluation ? "EVALUATION" : "ANY",
      });
      this.cancelDialog();
      this.getLeads();
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },

    getCoordinator(item) {
      if (
        item.relations == null ||
        item.relations == undefined ||
        item.relations.length == 0
      ) {
        return "-";
      } else {
        return item.relations[0].employee.fullname;
      }
    },
    getRDate(item) {
      if (
        item.relations == null ||
        item.relations == undefined ||
        item.relations.length == 0
      ) {
        return "";
      } else {
        return item.relations[0].createdAt;
      }
    },

    prettyDate(date) {
      if (date === null || date === "") return "-";

      return moment(date).format("MM/DD/YYYY");
    },

    cancelDialog() {
      this.dialogNote = false;
      this.loadingAcciones = false;
      this.dialogLead = false;
      this.evaluation = false;
      this.descriptioncall = "";
      this.detailsnote = "";
    },

    refresh(type) {
      switch (type) {
        case "dates":
          this.dateTo = "";
          this.dateFrom = "";
          this.body.dates = null;
          break;
        default:
          this.body.query = null;
          break;
      }
      this.getLeads();
    },
  },
});
</script>
<style lang="scss" scoped>
.cardexpand {
  width: 100vh !important;
}
.texts {
  margin-top: -26px !important;
  margin-bottom: 10px !important;
}
.texts1 {
  margin-top: -40px !important;
  margin-bottom: 10px !important;
}
.texts2 {
  margin-top: -40px !important;
  margin-bottom: 8px !important;
}
.actions {
  margin-top: -50px !important;
}
.texts3 {
  margin-top: -40px !important;
  margin-bottom: 5px !important;
}
.borderdivider {
  border: 1px solid;
}
.test {
  border: 1px solid red;
}
.leaddetails {
  text-decoration: none;
  margin: 2px 0px;
}
</style>
